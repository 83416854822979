/* eslint-disable no-console */
import { FC, lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { BigPulseLoader } from '../components/loaders'
import { ERROR_PAGE_PATH, PAGE_NOT_FOUND } from '../constants'
// import OrderCompletePage from '../pages/orderComplete'
import { persistor, store } from '../store'
import ProtectedRoute from './protectedRoute'
import redirects from './redirections.json'
import ScrollToTop from './scrollToTop'
import vendors from './vendors.json'

const RedirectionNet = lazy(() => import(/* webpackChunkName: "redirectionNetPage" */ '../pages/redirectNet'))
const RedirectionNetPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <RedirectionNet />
  </Suspense>
)

const Home = lazy(() => import(/* webpackChunkName: "homePage" */ '../pages/homepage'))
const HomePage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Home />
  </Suspense>
)

const ProductDetails = lazy(() => import(/* webpackChunkName: "productDetailsPage" */ '../pages/productDetails'))
const ProductDetailsPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <ProductDetails />
  </Suspense>
)

const ProductList = lazy(() => import(/* webpackChunkName: "productListPage" */ '../pages/productList'))
const ProductListPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <ProductList />
  </Suspense>
)

const Vendor = lazy(() => import(/* webpackChunkName: "vendorsPage" */ '../pages/vendors'))
const VendorPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Vendor />
  </Suspense>
)
const Faculty = lazy(() => import(/* webpackChunkName: "facultyPage" */ '../pages/faculty'))
const FacultyPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Faculty />
  </Suspense>
)
const Cart = lazy(() => import(/* webpackChunkName: "cartPage" */ '../pages/cart'))
const CartPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Cart />
  </Suspense>
)

const AccountManagement = lazy(() => import(/* webpackChunkName: "accountManagementPage" */ '../pages/accountManagement'))
const AccountManagementPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <AccountManagement />
  </Suspense>
)

const Help = lazy(() => import(/* webpackChunkName: "helpPage" */ '../pages/help'))
const HelpPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Help />
  </Suspense>
)

const FindMySchool = lazy(() => import(/* webpackChunkName: "findMySchoolPage" */ '../pages/findMySchool'))
const FindMySchoolPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <FindMySchool />
  </Suspense>
)

const Error = lazy(() => import(/* WebpackChunkName: "errorPage" */ '../pages/error'))
const PageNotFoundPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Error errorCode={PAGE_NOT_FOUND} />
  </Suspense>
)

const TechnicalIssuePage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Error />
  </Suspense>
)

const BlogContent = lazy(() => import(/* WebpackChunkName: "blogContentPage" */ '../pages/blogContent'))
const BlogContentPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <BlogContent />
  </Suspense>
)

const BlogList = lazy(() => import(/* WebpackChunkName: "blogListPage" */ '../pages/blogList'))
const BlogListPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <BlogList />
  </Suspense>
)

const Logout = lazy(() => import(/* WebpackChunkName: "logoutPage" */ '../pages/redirectNet/logout'))
const LogoutPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <Logout />
  </Suspense>
)

const SellWithUs = lazy(() => import(/* WebpackChunkName: "sellWithUsPage" */ '../pages/sellWithUs'))
const SellWithUsPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <SellWithUs />
  </Suspense>
)

const BulkOrders = lazy(() => import(/* WebpackChunkName: "bulkOrdersPage" */ '../pages/bulkOrders'))
const BulkOrdersPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <BulkOrders />
  </Suspense>
)

const About = lazy(() => import(/* WebpackChunkName: "aboutPage" */ '../pages/about'))
const AboutPage = () => (
  <Suspense fallback={<BigPulseLoader />}>
    <About />
  </Suspense>
)

const Routing: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<BigPulseLoader />} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <Switch>
            {redirects.map(({ from, to }) => (
              <Redirect key={`${from}->${to}`} from={from} to={to} />
            ))}
            <Route exact path="/" component={HomePage} />
            <ProtectedRoute exact path="/account" component={AccountManagementPage} />
            <ProtectedRoute exact path="/account/:content(order-history|info|change-password)" component={AccountManagementPage} />
            <ProtectedRoute exact path="/account/:content(order-history|info|change-password)" component={AccountManagementPage} />
            {/* <Route exact path="/account" component={AccountManagementPage} />
            <Route exact path="/account/:content(order-history|info|change-password)" component={AccountManagementPage} /> */}
            <Route exact path="/help" component={HelpPage} />
            <Route exact path="/faculty" component={FacultyPage} />
            <Route exact path="/products/:slug" component={ProductDetailsPage} />
            {/* <ProtectedRoute exact path="/orders/:orderId" component={OrderDetailsPage} /> */}
            <Route exact path="/shop" component={ProductListPage} />
            <Route exact path="/students" component={HomePage} />
            <Route exact path="/school" component={FindMySchoolPage} />
            <Route exact path="/sell-with-us" component={SellWithUsPage} />
            <Route exact path="/bulk-orders" component={BulkOrdersPage} />
            <Route exact path="/about" component={AboutPage} />
            {/* <Route exact path="/checkout/complete" component={OrderCompletePage} /> */}
            <Route exact path="/signin/code" component={RedirectionNetPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path={`/:vendor(${vendors.join('|')})`} component={VendorPage} />
            {/* <ProtectedRoute exact path="/checkout" component={CheckoutPage} /> */}
            {/* <Route exact path="/checkout" component={CheckoutPage} /> */}
            <Route exact path={['/blogs/:filterOption', '/blogs']} component={BlogListPage} />
            <Route exact path="/blog/:id" component={BlogContentPage} />
            <Route exact path={ERROR_PAGE_PATH} component={TechnicalIssuePage} />
            <Route exact path="*" component={PageNotFoundPage} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default Routing
