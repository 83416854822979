export const normalizeNumber = (num: number): string => (num >= 10 ? `${num}` : `0${num}`)
export const getCurrentDate = (): string => {
  const now = new Date()
  return `${now.getFullYear()}-${normalizeNumber(now.getMonth() + 1)}-${normalizeNumber(now.getDate())}`
}
export const getDateString = (time = ''): string => {
  const timeArray = new Date(time).toString().split(' ')
  const dateString = timeArray.slice(1, 3).join(' ')
  const yearString = timeArray[3]
  return `${dateString}, ${yearString}`
}

export const getDaysLeft = (ts: string): number => {
  const now: number = new Date().getTime()
  const target: number = new Date(ts).getTime()

  return Math.round((target - now) / (100 * 60 * 60 * 24) / 10)
}
