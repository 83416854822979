import { ASSETS } from './paths'

export const BENEFITS = [
  {
    title: 'Best Discounts',
    content:
      'Providing the best discounts to students and faculty is our top priority. Our partnerships with leading software publishers ensure you get the lowest prices on academic software worldwide.',
    icon: `${ASSETS}/pages/about/3-icon-discounts.svg`,
  },
  {
    title: 'Guaranteed Security',
    content:
      'You can count on us to keep your personal information and data secure. OnTheHub is compliant with all ISO standards, providing safe and legal software distribution.',
    icon: `${ASSETS}/pages/about/3-icon-security.svg`,
  },
  {
    title: 'Student Success',
    content:
      'We care about the future of students. That’s why our software discounts, awesome contests, and helpful blog posts are all focused on providing the resources needed for student success.',
    icon: `${ASSETS}/pages/about/3-icon-student-success.svg`,
  },
]
