import { ID_PAGE_MAIN_ELEMENT } from '../../constants'

export * from './createContainer'
export * from './detectDevice'
export * from './redirectTo'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const isIE = () => !!window.document.documentMode

/**
 * Scroll to the top of the page by bringing  into view the first element of the page container.
 *
 * @returns Nothing
 */
export const scrollPageToTop = () => scrollPageTo(ID_PAGE_MAIN_ELEMENT)

export const scrollPageTo = (elementId: string) => {
  try {
    const firstChildOfPage = document.getElementById(elementId)?.firstElementChild
    if (!firstChildOfPage) return
    if (isIE()) firstChildOfPage.scrollIntoView(true)
    else firstChildOfPage.scrollIntoView({ behavior: 'smooth' })
  } catch {
    return
  }
}

// https://stackoverflow.com/a/54310080
export const decodeURIComponentSafe = (s: string) => {
  if (!s) return s
  return decodeURIComponent(s.replace(/%(?![0-9a-fA-F]{2})/g, '%25'))
}
