/* eslint-disable no-console */

import { BOOTSTRAP_URL, SITE_CATEGORIES_URL } from '../constants'
import { Bootstrap, SiteCategory } from '../types'
import { env } from '../utils/env'
import { cacheBusters, buildHashCache } from './cache'
import { api, getHeaders } from './webstoreApiRequests'

export const getBootstrap = async (): Promise<Bootstrap | undefined> => {
  const oldBuildHash = await buildHashCache.get()
  const newBuildHash = env('REACT_APP_BUILD_HASH')

  if (oldBuildHash !== newBuildHash) {
    // who you gonna call 👻 ?
    await cacheBusters()

    if (newBuildHash) await buildHashCache.set(newBuildHash)
  }
  const { data: res } = await api.get(BOOTSTRAP_URL)
  return res.data
}
export const getCategories = async (): Promise<SiteCategory[]> => {
  return (
    await api.get(SITE_CATEGORIES_URL, {
      headers: await getHeaders(),
    })
  ).data.data
}
