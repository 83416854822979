import { ReactElement } from 'react'
import { render } from 'react-dom'

import { createContainer } from '../../utils/domHelpers/createContainer'
import Snackbar from './snackbar'
import { SnackbarType, Position } from './types'

export const openSnackbar = (
  content: (ReactElement | string | Element) | (ReactElement | string | Element)[],
  duration: number,
  position?: Position,
  snackbarType?: SnackbarType
): void => {
  //to avoid opening snackbar on multiple button clicks
  const state = {
    isOpen: false,
  }
  // default values for position and snackbar type
  position = position || 'topRight'
  snackbarType = snackbarType || `plain`

  const container = createContainer('portal')

  if (!state.isOpen) {
    state.isOpen = true
    render(
      <Snackbar
        position={position}
        snackbarType={snackbarType}
        duration={duration}
        label={typeof content === 'string' ? content : undefined}
      >
        {content}
      </Snackbar>,
      container
    )

    setTimeout(() => {
      container.parentElement?.removeChild(container)
      state.isOpen = false
    }, duration * 1000)
  }
}
