/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { getHomeBanners } from '../../services/wordpressApiServices'
import { HomepageBanner } from '../../types'

const homeBanners: HomepageBanner[] = [] // Data will come from localForage through react-persist

const initialState = {
  homeBanners: homeBanners,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get homeBanner
export const fetchHomeBanners = createAsyncThunk('homeBanner/getAll', async (_, thunkAPI) => {
  try {
    const getHomeBannersWithRetry = wrapWithRetryAsync(getHomeBanners)
    const homeBanners = (await getHomeBannersWithRetry()) || []

    return homeBanners
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const homeBannerSlice = createSlice({
  name: 'homeBanner',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeBanners.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHomeBanners.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.homeBanners = action.payload
      })
      .addCase(fetchHomeBanners.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload as string
        state.homeBanners = []
      })
  },
})

export const { reset } = homeBannerSlice.actions
export default homeBannerSlice.reducer
