import { WORDPRESS_BLOG_IMAGE_URL, LOCAL_BLOG_IMAGE_URL } from '../constants'
import { Blog, BlogMedia } from '../types'
import { random } from './random'

export const sortBlogImages = (media: BlogMedia[]) => {
  const sorted = [...media].sort((a, b) => b.width - a.width)
  return sorted
}

export const getFirstBlogMediaUrl = (sorted: BlogMedia[]) => {
  return WORDPRESS_BLOG_IMAGE_URL + sorted[0].file
}

export const getRandomBlogBannerUrl = () => {
  return `${LOCAL_BLOG_IMAGE_URL}/Desktop_${random(4, 1)}x2.jpg`
}

export const getRandomBlogCardUrl = () => {
  // TODO: The following line should really be handled by CSS.
  const imageSize = window.innerWidth < 800 ? 'Mobile' : 'Desktop'
  return `${LOCAL_BLOG_IMAGE_URL}/${imageSize}_${random(4, 1)}.jpg`
}

export const getDefaultBlogImage = (blog: Blog) => {
  const sorted = sortBlogImages(blog.media)
  const defaultBlogImage = sorted.length ? getFirstBlogMediaUrl(sorted) : getRandomBlogBannerUrl()
  return defaultBlogImage
}

export const getDefaultBlogBannerImage = (blog: Blog) => {
  const sorted = sortBlogImages(blog.media)
  const defaultBlogImage = sorted.length ? getFirstBlogMediaUrl(sorted) : getRandomBlogBannerUrl()
  return defaultBlogImage
}

export const getDefaultBlogCardImage = (blog: Blog) => {
  const sorted = sortBlogImages(blog.media)
  const defaultBlogImage = sorted.length ? getFirstBlogMediaUrl(sorted) : getRandomBlogCardUrl()
  return defaultBlogImage
}
