import { FC, ReactElement } from 'react'

import { ASSETS } from '../../constants'
import Image from '../atoms/images/image'

import './index.css'

export const Loader: FC<{ loaderType: 'tail' | 'tail_w' | 'dots' | 'pulse'; style?: { [key: string]: string } }> = ({
  loaderType,
  style,
}): ReactElement => {
  return (
    <Image
      style={style}
      disableLazy={true}
      className="loader"
      src={`${ASSETS}/loaders/${loaderType}.svg`}
      alt={`${loaderType}-loader`}
      size={{ width: 50, height: 50 }}
    />
  )
}

export const TailLoader: FC<{ white?: boolean }> = ({ white }): ReactElement => {
  return (
    <div className="loader-container fade-in">
      <Loader loaderType={`tail${white ? '_w' : ''}`} />
    </div>
  )
}

export const DotsLoader: FC = (): ReactElement => {
  return (
    <div className="loader-container fade-in">
      <Loader loaderType="dots" />
    </div>
  )
}

export const PulseLoader: FC = (): ReactElement => {
  return (
    <div className="loader-container fade-in">
      <Loader loaderType="pulse" />
    </div>
  )
}

export const BigPulseLoader: FC<{ style?: { [key: string]: string }; noFade?: boolean }> = ({ style, noFade }): ReactElement => {
  return (
    <div className={`loader-container big-pulse ${noFade ? '' : 'fade-in'}`} style={style}>
      <Loader loaderType="pulse" />
    </div>
  )
}
