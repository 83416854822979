/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'

import { openSnackbar } from '../../components/snackbar'
import { ACCESS_GUARANTEE_PRODUCT_TYPE, EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE } from '../../constants'
import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { RootState } from '../../store'
import { ApiCartItem, CartItem, INCLUSION_TYPE_OPTIONS, ProductWithVariants } from '../../types'
import {
  constructCartItem,
  filterCart,
  getAddOnsDependenciesFromCart,
  isAccessGuarantee,
  isAccessGuaranteeApplicableToCart,
  sortCart,
} from '../../utils'
import { addCartItem, deleteCartItem, getCartItems, updateCartItem } from './cartService'

type CartStateType = {
  cartItems: CartItem[]
  isAccessGuaranteeAppliedToCart: boolean
  accessGuarantee: CartItem | undefined
  userOptOutEAG: boolean
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}

const initialCartitems: CartItem[] = [] // Data will come from localForage through react-persist

const initialState: CartStateType = {
  cartItems: initialCartitems,
  isAccessGuaranteeAppliedToCart: false,
  accessGuarantee: undefined,
  userOptOutEAG: false, // users are opted in by default
  isError: false,
  isSuccess: false,
  isLoading: false,
}

// Get cartItems
export const fetchCartItems = createAsyncThunk('cartItems/getAll', async (_, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { products: fetchedProducts } = pre.productReducer

    const getCartItemsWithRetry = wrapWithRetryAsync(getCartItems)
    const { data } = await getCartItemsWithRetry()

    const cartItemsRes: CartItem[] = (data.cartItems as ApiCartItem[]).map((item: ApiCartItem) => constructCartItem(fetchedProducts, item))
    const unavailableItems = cartItemsRes.filter((ci) => !ci.product || !ci.variant)
    if (unavailableItems.length) {
      unavailableItems.forEach(async (item) => {
        await thunkAPI.dispatch(deleteItemFromCart(item))
      })
      openSnackbar(t('Cart_item_removed_due_to_unavailability'), 8, 'topLeft', 'info')
    }

    const sortedCartItems = sortCart(cartItemsRes)
    await thunkAPI.dispatch(updateIsAccessGuaranteeAppliedToCart(sortedCartItems))

    return sortedCartItems
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateItemInCart = createAsyncThunk('cartItems/update', async (cartItem: CartItem, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { products: fetchedProducts } = pre.productReducer
    const { cartItems } = pre.cartReducer

    const res = await updateCartItem(cartItem)

    const { data } = res
    const cartItemsInfo: ApiCartItem[] = data.cartItems.map((ci: ApiCartItem) => ({
      ...cartItem.cartItemApiInfo,
      ...ci,
    }))

    const newCartItems = cartItemsInfo.map((ci) => constructCartItem(fetchedProducts, ci))
    const updated = [...cartItems]
    newCartItems.forEach((ci) => {
      const itemIndex = cartItems.findIndex((item) => item.cartItemId === ci.cartItemId)
      updated.splice(itemIndex, 1, ci)
    })
    return updated
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateVariantInCart = createAsyncThunk<void, { oldCartItem: CartItem; newCartItem: CartItem }>(
  'cartItems/updateVariant',
  async ({ oldCartItem, newCartItem }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(deleteItemFromCart(oldCartItem)).then(unwrapResult)
      await thunkAPI.dispatch(addItemToCart(newCartItem)).then(unwrapResult)
    } catch (error: any) {
      const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAccessGuarantee = createAsyncThunk('cartItems/getAccessGuarantee', async (_, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { products: fetchedProducts } = pre.productReducer
    const agProduct = fetchedProducts.find((p) =>
      p.variants.find(
        (v) => v.productVariantType === ACCESS_GUARANTEE_PRODUCT_TYPE || v.productVariantType === EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE
      )
    )
    const agVariant = agProduct?.variants[0]
    return {
      quantity: 1,
      cartItemId: '',
      product: agProduct,
      variant: agVariant,
      cartItemApiInfo: {
        cartItemId: '',
        productVariantId: agVariant?.productVariantId as string,
        productVariantName: agVariant?.description.short as string,
        productVariantType: agVariant?.productVariantType as string,
        quantity: 1,
        priceInCart: agVariant?.pricing.price as number,
        deliveryType: agVariant?.deliveryType as string,
      },
    }
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateIsAccessGuaranteeAppliedToCart = createAsyncThunk(
  'cartItems/isAccessGuaranteeAppliedToCart',
  async (cartItems: CartItem[], thunkAPI) => {
    try {
      return cartItems.some((i) => i.cartItemApiInfo.productVariantType === EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE)
    } catch (error: any) {
      const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Action to update whether user has chosen to opt out of EAG. This is to avoid the user having to constantly remove the EAG item from his cart.
export const updateUserOptOutEAG = createAsyncThunk('cartItems/updateUserOptOutEAG', async (newStatus: boolean, thunkAPI) => {
  try {
    // Add code here to cache if users complain that they have to optout everytime they log in again.
    return newStatus
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

/**
 * Helper method that adds an item to the cart via an API call.
 * If that item has addons, the API will add and return them as well.
 *
 * @param cartItem Item to be added to the cart via the API
 * @param products The list of products
 * @returns An array newly added item or item with addons
 */
export const addItem = async (cartItem: CartItem, products: ProductWithVariants[]) => {
  const res = await addCartItem(cartItem)

  const { data } = res
  const newCartItemsInfo: ApiCartItem[] = data.cartItems.map((ci: ApiCartItem) => ({
    ...cartItem.cartItemApiInfo,
    ...ci,
  }))
  return newCartItemsInfo.map((ci) => constructCartItem(products, ci))
}

/**
 * Helper function that adds an item to the cart.
 * If that item has dependencies such as an access Guarantee,
 * the function will attempt to add it to the cart as well.
 *
 * @param cartItem Item to be added to the cart via the API
 * @param rootState The current react state.
 * @returns An array of newly added items
 */
const addItemWithDependencies = async (cartItem: CartItem, rootState: RootState) => {
  const { cartItems, accessGuarantee, userOptOutEAG, isAccessGuaranteeAppliedToCart } = rootState.cartReducer
  const { products } = rootState.productReducer

  let newCartItems: CartItem[] = [...cartItems]
  newCartItems = newCartItems.concat(await addItem(cartItem, products))

  if (!isAccessGuaranteeAppliedToCart && accessGuarantee && !userOptOutEAG && isAccessGuaranteeApplicableToCart(newCartItems)) {
    newCartItems = newCartItems.concat(await addItem(accessGuarantee, products))
  }
  return newCartItems
}

export const addItemToCart = createAsyncThunk('cartItems/addToCart', async (cartItem: CartItem, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { cartItems } = pre.cartReducer
    const alreadyInCart = cartItems.find((i) => i.variant?.productVariantId === cartItem.variant?.productVariantId)
    if (alreadyInCart) {
      await thunkAPI
        .dispatch(
          updateItemInCart({
            ...alreadyInCart,
            cartItemId: alreadyInCart.cartItemId,
            quantity: alreadyInCart.quantity + cartItem.quantity,
            cartItemApiInfo: {
              ...alreadyInCart.cartItemApiInfo,
              quantity: alreadyInCart.quantity + cartItem.quantity,
              cartItemId: alreadyInCart.cartItemId,
            },
          })
        )
        .then(unwrapResult)
    } else {
      const updated = await addItemWithDependencies(cartItem, pre)
      await thunkAPI.dispatch(updateIsAccessGuaranteeAppliedToCart(updated))
      return sortCart(updated)
    }
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const deleteItemFromCart = createAsyncThunk('cartItems/deleteFromCart', async (cartItem: CartItem, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { cartItems } = pre.cartReducer as CartStateType

    const addOnsToRemoveFromCart = getAddOnsDependenciesFromCart(cartItems, cartItem, INCLUSION_TYPE_OPTIONS.OPTIONAL)
    if (addOnsToRemoveFromCart) {
      const deleteAddonsPromises: any[] = []
      addOnsToRemoveFromCart.forEach(async (variantId: string) => {
        const addOnCartItem = cartItems.find((c) => c.cartItemApiInfo.productVariantId === variantId)
        if (addOnCartItem) {
          deleteAddonsPromises.push(deleteCartItem(addOnCartItem))
        }
      })
      await Promise.all(deleteAddonsPromises)
    }

    await deleteCartItem(cartItem)

    if (isAccessGuarantee(cartItem.cartItemApiInfo.productVariantType)) {
      await thunkAPI.dispatch(updateUserOptOutEAG(true))
    }

    let updated = filterCart(cartItems, cartItem, addOnsToRemoveFromCart)

    const accessGuaranteeInCart = updated.find((c) => isAccessGuarantee(c.cartItemApiInfo.productVariantType))
    if (accessGuaranteeInCart && !isAccessGuaranteeApplicableToCart(updated)) {
      updated = filterCart(updated, accessGuaranteeInCart, [])
    }

    await thunkAPI.dispatch(updateIsAccessGuaranteeAppliedToCart(updated))

    return updated
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const clearCart = createAsyncThunk('cartItems/clear', async (_, thunkAPI) => {
  try {
    reset()
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const cartSlice = createSlice({
  name: 'cartItems',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cartItems = action.payload
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
      .addCase(updateItemInCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateItemInCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cartItems = action.payload
      })
      .addCase(updateItemInCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
      .addCase(getAccessGuarantee.fulfilled, (state, action) => {
        state.accessGuarantee = action.payload
      })
      .addCase(getAccessGuarantee.rejected, (state, action) => {
        state.accessGuarantee = undefined
      })
      .addCase(updateIsAccessGuaranteeAppliedToCart.fulfilled, (state, action) => {
        state.isAccessGuaranteeAppliedToCart = action.payload
      })
      .addCase(updateUserOptOutEAG.fulfilled, (state, action) => {
        state.userOptOutEAG = action.payload
      })
      .addCase(addItemToCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        if (action.payload) state.cartItems = action.payload
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
      .addCase(deleteItemFromCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteItemFromCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cartItems = action.payload
      })
      .addCase(deleteItemFromCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
      .addCase(updateVariantInCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateVariantInCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updateVariantInCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
      .addCase(clearCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(clearCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cartItems = []
      })
      .addCase(clearCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
      })
  },
})

export const { reset } = cartSlice.actions
export default cartSlice.reducer
