import { env } from '../utils/env'

export const SITECATEGORIES_DATA_ANALYSIS = env('REACT_APP_SITECATEGORIES_DATA_ANALYSIS')
export const SITECATEGORIES_PHOTO_VIDEO_DESIGN = env('REACT_APP_SITECATEGORIES_PHOTO_VIDEO_DESIGN')
export const SITECATEGORIES_SECURITY_UTILITIES = env('REACT_APP_SITECATEGORIES_SECURITY_UTILITIES')
export const SITECATEGORIES_BUSINESS_OFFICE = env('REACT_APP_SITECATEGORIES_BUSINESS_OFFICE')
export const SITECATEGORIES_BOOKS = env('REACT_APP_SITECATEGORIES_BOOKS')
export const SITECATEGORIES_DATA_ANALYSIS_PRODUCER_LIST = env('REACT_APP_SITECATEGORIES_DATA_ANALYSIS_PRODUCER_LIST')
export const SITECATEGORIES_PHOTO_VIDEO_DESIGN_PRODUCER_LIST = env('REACT_APP_SITECATEGORIES_PHOTO_VIDEO_DESIGN_PRODUCER_LIST')
export const SITECATEGORIES_SECURITY_UTILITIES_PRODUCER_LIST = env('REACT_APP_SITECATEGORIES_SECURITY_UTILITIES_PRODUCER_LIST')
export const SITECATEGORIES_BUSINESS_OFFICE_PRODUCER_LIST = env('REACT_APP_SITECATEGORIES_BUSINESS_OFFICE_PRODUCER_LIST')
export const SITECATEGORIES_BOOKS_PRODUCER_LIST = env('REACT_APP_SITECATEGORIES_BOOKS_PRODUCER_LIST')
