import { DEFAULT_TENANT } from './bootstrap'
import { topicIdMap } from './help'

export enum COOKIE_BANNER_OPTIONS {
  TOP = 'top',
  BOTTOM = 'bottom',
  NONE = 'none',
}
export const COOKIES_ACCEPT_BUTTON_TEXT = 'I Accept'
export const COOKIES_BANNER_MESSAGE = 'By using this site you agree to use of cookies for analytics, personalized content and ads.'
export const COOKIE_NAME = `${DEFAULT_TENANT}.onthehub`
export const PRIVACY_POLICY = 'View Privacy Policy'
export const PRIVACY_POLICY_LINK = `/help?helpCategoryId=${topicIdMap.policies}&helpTopicId=${topicIdMap.privacyPolicy}`
export const defaultBannerContent = {
  buttonText: COOKIES_ACCEPT_BUTTON_TEXT,
  location: COOKIE_BANNER_OPTIONS.BOTTOM,
  cookieBannerMessage: COOKIES_BANNER_MESSAGE,
}
export const defaultStyleClasses = {
  buttonClasses: 'buttonDefaultClass light',
  containerClasses: 'containerDefaultClass',
}
export const defaultCookieProperties = {
  cookieName: COOKIE_NAME,
  cookieValue: { isSet: false },
  expires: 1,
}
