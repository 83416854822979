export const SERVED_COUNTRIES = [
  {
    Country: 'Albania',
    CountryCode: 'ALB',
  },
  {
    Country: 'Algeria',
    CountryCode: 'DZA',
  },
  {
    Country: 'Andorra',
    CountryCode: 'AND',
  },
  {
    Country: 'Angola',
    CountryCode: 'AGO',
  },
  {
    Country: 'Argentina',
    CountryCode: 'ARG',
  },
  {
    Country: 'Armenia',
    CountryCode: 'ARM',
  },
  {
    Country: 'Australia',
    CountryCode: 'AUS',
  },
  {
    Country: 'Austria',
    CountryCode: 'AUT',
  },
  {
    Country: 'Azerbaijan',
    CountryCode: 'AZE',
  },
  {
    Country: 'Bahrain',
    CountryCode: 'BHR',
  },
  {
    Country: 'Bangladesh',
    CountryCode: 'BGD',
  },
  {
    Country: 'Barbados',
    CountryCode: 'BRB',
  },
  {
    Country: 'Belarus',
    CountryCode: 'BLR',
  },
  {
    Country: 'Belgium',
    CountryCode: 'BEL',
  },
  {
    Country: 'Benin',
    CountryCode: 'BEN',
  },
  {
    Country: 'Bolivia',
    CountryCode: 'BOL',
  },
  {
    Country: 'Bosnia And Herzegovina',
    CountryCode: 'BIH',
  },
  {
    Country: 'Botswana',
    CountryCode: 'BWA',
  },
  {
    Country: 'Brazil',
    CountryCode: 'BRA',
  },
  {
    Country: 'Brunei Darussalam',
    CountryCode: 'BRN',
  },
  {
    Country: 'Bulgaria',
    CountryCode: 'BGR',
  },
  {
    Country: 'Cameroon',
    CountryCode: 'CMR',
  },
  {
    Country: 'Canada',
    CountryCode: 'CAN',
  },
  {
    Country: 'Chile',
    CountryCode: 'CHL',
  },
  {
    Country: 'China',
    CountryCode: 'CHN',
  },
  {
    Country: 'Colombia',
    CountryCode: 'COL',
  },
  {
    Country: 'Congo, The Democratic Republic Of The',
    CountryCode: 'COD',
  },
  {
    Country: 'Costa Rica',
    CountryCode: 'CRI',
  },
  {
    Country: "Cote D'Ivoire",
    CountryCode: 'CIV',
  },
  {
    Country: 'Croatia',
    CountryCode: 'HRV',
  },
  {
    Country: 'Cyprus',
    CountryCode: 'CYP',
  },
  {
    Country: 'Czech Republic',
    CountryCode: 'CZE',
  },
  {
    Country: 'Denmark',
    CountryCode: 'DNK',
  },
  {
    Country: 'Dominican Republic',
    CountryCode: 'DOM',
  },
  {
    Country: 'Ecuador',
    CountryCode: 'ECU',
  },
  {
    Country: 'Egypt',
    CountryCode: 'EGY',
  },
  {
    Country: 'El Salvador',
    CountryCode: 'SLV',
  },
  {
    Country: 'Estonia',
    CountryCode: 'EST',
  },
  {
    Country: 'Ethiopia',
    CountryCode: 'ETH',
  },
  {
    Country: 'Faroe Islands',
    CountryCode: 'FRO',
  },
  {
    Country: 'Fiji',
    CountryCode: 'FJI',
  },
  {
    Country: 'Finland',
    CountryCode: 'FIN',
  },
  {
    Country: 'France',
    CountryCode: 'FRA',
  },
  {
    Country: 'French Guiana',
    CountryCode: 'GUF',
  },
  {
    Country: 'Gambia',
    CountryCode: 'GMB',
  },
  {
    Country: 'Georgia',
    CountryCode: 'GEO',
  },
  {
    Country: 'Germany',
    CountryCode: 'DEU',
  },
  {
    Country: 'Ghana',
    CountryCode: 'GHA',
  },
  {
    Country: 'Greece',
    CountryCode: 'GRC',
  },
  {
    Country: 'Guadeloupe',
    CountryCode: 'GLP',
  },
  {
    Country: 'Guatemala',
    CountryCode: 'GTM',
  },
  {
    Country: 'Honduras',
    CountryCode: 'HND',
  },
  {
    Country: 'Hong Kong',
    CountryCode: 'HKG',
  },
  {
    Country: 'Hungary',
    CountryCode: 'HUN',
  },
  {
    Country: 'Iceland',
    CountryCode: 'ISL',
  },
  {
    Country: 'India',
    CountryCode: 'IND',
  },
  {
    Country: 'Indonesia',
    CountryCode: 'IDN',
  },
  {
    Country: 'Iraq',
    CountryCode: 'IRQ',
  },
  {
    Country: 'Ireland',
    CountryCode: 'IRL',
  },
  {
    Country: 'Israel',
    CountryCode: 'ISR',
  },
  {
    Country: 'Italy',
    CountryCode: 'ITA',
  },
  {
    Country: 'Jamaica',
    CountryCode: 'JAM',
  },
  {
    Country: 'Japan',
    CountryCode: 'JPN',
  },
  {
    Country: 'Jordan',
    CountryCode: 'JOR',
  },
  {
    Country: 'Kazakhstan',
    CountryCode: 'KAZ',
  },
  {
    Country: 'Kenya',
    CountryCode: 'KEN',
  },
  {
    Country: 'Korea, (South) Republic Of',
    CountryCode: 'KOR',
  },
  {
    Country: 'Kosovo',
    CountryCode: 'XKS',
  },
  {
    Country: 'Kuwait',
    CountryCode: 'KWT',
  },
  {
    Country: 'Latvia',
    CountryCode: 'LVA',
  },
  {
    Country: 'Lebanon',
    CountryCode: 'LBN',
  },
  {
    Country: 'Liechtenstein',
    CountryCode: 'LIE',
  },
  {
    Country: 'Lithuania',
    CountryCode: 'LTU',
  },
  {
    Country: 'Luxembourg',
    CountryCode: 'LUX',
  },
  {
    Country: 'Macedonia, The Former Yugoslav Republic Of',
    CountryCode: 'MKD',
  },
  {
    Country: 'Madagascar',
    CountryCode: 'MDG',
  },
  {
    Country: 'Malawi',
    CountryCode: 'MWI',
  },
  {
    Country: 'Malaysia',
    CountryCode: 'MYS',
  },
  {
    Country: 'Malta',
    CountryCode: 'MLT',
  },
  {
    Country: 'Mauritius',
    CountryCode: 'MUS',
  },
  {
    Country: 'Mexico',
    CountryCode: 'MEX',
  },
  {
    Country: 'Moldova, Republic Of',
    CountryCode: 'MDA',
  },
  {
    Country: 'Mongolia',
    CountryCode: 'MNG',
  },
  {
    Country: 'Morocco',
    CountryCode: 'MAR',
  },
  {
    Country: 'Netherlands',
    CountryCode: 'NLD',
  },
  {
    Country: 'New Caledonia',
    CountryCode: 'NCL',
  },
  {
    Country: 'New Zealand',
    CountryCode: 'NZL',
  },
  {
    Country: 'Nicaragua',
    CountryCode: 'NIC',
  },
  {
    Country: 'Nigeria',
    CountryCode: 'NGA',
  },
  {
    Country: 'Norway',
    CountryCode: 'NOR',
  },
  {
    Country: 'Oman',
    CountryCode: 'OMN',
  },
  {
    Country: 'Pakistan',
    CountryCode: 'PAK',
  },
  {
    Country: 'Palestinian Territory, Occupied',
    CountryCode: 'PSE',
  },
  {
    Country: 'Panama',
    CountryCode: 'PAN',
  },
  {
    Country: 'Peru',
    CountryCode: 'PER',
  },
  {
    Country: 'Philippines',
    CountryCode: 'PHL',
  },
  {
    Country: 'Poland',
    CountryCode: 'POL',
  },
  {
    Country: 'Portugal',
    CountryCode: 'PRT',
  },
  {
    Country: 'Puerto Rico',
    CountryCode: 'PRI',
  },
  {
    Country: 'Qatar',
    CountryCode: 'QAT',
  },
  {
    Country: 'Reunion',
    CountryCode: 'REU',
  },
  {
    Country: 'Romania',
    CountryCode: 'ROU',
  },
  {
    Country: 'Russian Federation',
    CountryCode: 'RUS',
  },
  {
    Country: 'Saudi Arabia',
    CountryCode: 'SAU',
  },
  {
    Country: 'Senegal',
    CountryCode: 'SEN',
  },
  {
    Country: 'Serbia',
    CountryCode: 'SRB',
  },
  {
    Country: 'Serbia And Montenegro',
    CountryCode: 'SCG',
  },
  {
    Country: 'Singapore',
    CountryCode: 'SGP',
  },
  {
    Country: 'Slovakia',
    CountryCode: 'SVK',
  },
  {
    Country: 'Slovenia',
    CountryCode: 'SVN',
  },
  {
    Country: 'South Africa',
    CountryCode: 'ZAF',
  },
  {
    Country: 'Spain',
    CountryCode: 'ESP',
  },
  {
    Country: 'Sri Lanka',
    CountryCode: 'LKA',
  },
  {
    Country: 'Swaziland',
    CountryCode: 'SWZ',
  },
  {
    Country: 'Sweden',
    CountryCode: 'SWE',
  },
  {
    Country: 'Switzerland',
    CountryCode: 'CHE',
  },
  {
    Country: 'Taiwan',
    CountryCode: 'TWN',
  },
  {
    Country: 'Tanzania, United Republic Of',
    CountryCode: 'TZA',
  },
  {
    Country: 'Thailand',
    CountryCode: 'THA',
  },
  {
    Country: 'Trinidad And Tobago',
    CountryCode: 'TTO',
  },
  {
    Country: 'Tunisia',
    CountryCode: 'TUN',
  },
  {
    Country: 'Turkey',
    CountryCode: 'TUR',
  },
  {
    Country: 'Uganda',
    CountryCode: 'UGA',
  },
  {
    Country: 'Ukraine',
    CountryCode: 'UKR',
  },
  {
    Country: 'United Arab Emirates',
    CountryCode: 'ARE',
  },
  {
    Country: 'United Kingdom',
    CountryCode: 'GBR',
  },
  {
    Country: 'United States',
    CountryCode: 'USA',
  },
  {
    Country: 'Uruguay',
    CountryCode: 'URY',
  },
  {
    Country: 'Uzbekistan',
    CountryCode: 'UZB',
  },
  {
    Country: 'Venezuela',
    CountryCode: 'VEN',
  },
  {
    Country: 'Vietnam',
    CountryCode: 'VNM',
  },
  {
    Country: 'Virgin Islands, U.S.',
    CountryCode: 'VIR',
  },
  {
    Country: 'Zimbabwe',
    CountryCode: 'ZWE',
  },
]
