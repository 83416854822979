/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, FC } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollToTop: FC<any> = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })

    return () => {
      unlisten()
    }
  }, [])
  return null
}

export default withRouter(ScrollToTop)
