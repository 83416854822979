export const FAQ = {
  left: [
    {
      question: 'How can I get free or discounted software through OnTheHub?',
      answer: (
        <div className="faq-answer-container">
          <p>There are two ways to take advantage of OnTheHub’s academic software discounts.</p>
          <ol>
            <li>
              <strong>Your School WebStore</strong> – You may be eligible to order discounted or free software if your school or department
              has its own OnTheHub WebStore. Use the
              <a href="/school" target="_blank" rel="noopener noreferrer">
                OnTheHub School Search
              </a>
              to find out if your school or department has a WebStore.
            </li>
            <li>
              <strong>The OnTheHub eStore</strong> – OnTheHub’s online store is open to all students, faculty, and school staff in many
              countries around the world. After setting up an account, you can purchase student-essential software from publishers like
              Microsoft, IBM, and Adobe for up to 90% off the retail price.
              <a href="/shop" target="_blank" rel="noopener noreferrer">
                Visit the eStore now
              </a>
              .
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: 'How are you able to offer free software?',
      answer: (
        <div className="faq-answer-container">
          <p>
            OnTheHub partners with select publishers, schools, and departments to provide software and other tools to eligible students and
            faculty at no charge. Possible free titles include Microsoft Windows, Microsoft Office, Adobe Creative Cloud, VMware Fusion, and
            more.
          </p>
          <p>
            Only students and faculty from participating schools can take advantage of these free software offers. Use our
            <a href="/school" target="_blank" rel="noopener noreferrer">
              School Search tool
            </a>
            to find out if your school is eligible today.
          </p>
        </div>
      ),
    },
    {
      question: 'What is your refund policy?',
      answer: (
        <div className="faq-answer-container">
          <p>
            Unfortunately, there are no refunds or exchanges on ANY items ordered through any OnTheHub site. All sales are final. We are
            unable to offer exchanges or refunds because product keys are issued with the software and once they are digitally issued, they
            cannot be returned to our publishers.
          </p>
          <p>
            When placing an order, you are responsible for ensuring that you have chosen the correct product, read all the system
            requirements, and entered accurate shipping information (when required).
          </p>
        </div>
      ),
    },
  ],
  right: [
    {
      question: 'What is your accessibility policy?',
      answer: (
        <div className="faq-answer-container">
          <p>
            OnTheHub is committed to supporting all of our customers in a manner that promotes dignity, independence, and equality of
            opportunity. To that end, we strive to meet (and, where possible, to exceed) global accessibility standards in the sites and
            services we offer.
          </p>
          <p>
            Our policies and procedures adhere to Canada’s Accessibility for Ontarians with Disabilities Act of 2005. Our website and
            WebStores incorporate design standards recommended under the Web Content Accessibility Guidelines (WCAG), version 2.0.
          </p>
        </div>
      ),
    },
    {
      question: "I'm having trouble downloading my software. What should I do?",
      answer: (
        <div className="faq-answer-container">
          <p>If you are experiencing difficulty downloading software, it may be for one of the following reasons.</p>
          <ul>
            <li>There may be a problem with your network connection. Make sure your computer still has access to the Internet.</li>
            <li>
              If you have been using a wireless connection to the Internet, try plugging your computer directly into your modem or router
              via a network cable and trying your download again. Wireless connections have a minor latency that can cause corruption during
              file transfers.
            </li>
            <li>
              Some networks used in schools, offices, or other public locations have security programs in place to prevent unauthorized
              downloading. Try your download again while connected to a different network.
            </li>
            <li>
              Active firewalls, antivirus and antispyware programs can be interfered with downloads. Try deactivating any such security
              systems on your computer and then try your download again. Be sure to reactivate these security programs as soon as your
              download is complete.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: 'How I find my product key or activation code for the software I download?',
      answer: (
        <div className="faq-answer-container">
          <p>
            If you ordered software from the eStore or your school WebStore, you can find any product keys or activation codes you need on
            your Order Details page.
          </p>
          <p>To find your product key or activation code:</p>
          <ol>
            <li>Sign in to the WebStore where you ordered the software.</li>
            <li>
              Click the arrow beside your username or email address (top-right corner), and click
              <strong> Your Account/Orders</strong>. You will be brought to a list of orders you have placed.
            </li>
            <li>
              Find the order for the software you need the key/code for, and click the
              <strong> View Details</strong> button beside it. You will be brought to your Order Details page, where all product keys and
              activation codes issued with the order are displayed.
            </li>
          </ol>
          <p>
            <strong>Note</strong>: In most cases, your key or code will allow a single activation of your software, though some programs and
            publishers allow more.
          </p>
        </div>
      ),
    },
  ],
}

export const topicIdMap = {
  yourAccount: 'd9df1ba2-c0b1-dd11-8e3b-001aa0b469f2',
  yourOrders: 'bdf2ac52-bfb1-dd11-8e3b-001aa0b469f2',
  accessGuarantee: '6ae89f09-32d0-e511-9416-b8ca3a5db7a1',
  shipping: '9d31b16f-c0b1-dd11-8e3b-001aa0b469f2',
  download: '3c0d1b03-28e3-dd11-bebe-0010182f4274',
  pricing: '95765e38-c2b1-dd11-8e3b-001aa0b469f2',
  policies: 'a9b66b18-c2b1-dd11-8e3b-001aa0b469f2',
  error: '257d4217-ee07-e011-b30a-0010182f4274',
  minitab: 'c2913d75-04fa-e211-93f2-b8ca3a5db7a1',
  privacyPolicy: '2118f433-dbb1-dd11-8e3b-001aa0b469f2',
}
