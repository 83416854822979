/* eslint-disable @typescript-eslint/no-explicit-any */
import { CART_ITEM_COLLECTION, CART_ITEM, BEGIN_CHECKOUT } from '../../constants'
import { api, getHeaders, accessTokenCache, handleUnauthorizedRequest } from '../../services'
import { CartItem } from '../../types'

export const getCartItems = async (): Promise<any> => {
  try {
    const { data } = await api.get(CART_ITEM_COLLECTION, {
      headers: await getHeaders(),
    })

    return data
  } catch (e: any) {
    if (e.response.status === 401) {
      await handleUnauthorizedRequest()
    } else throw e
  }
}

export const addCartItem = async (cartItem: CartItem): Promise<any> => {
  try {
    const headers = await getHeaders()
    const { data: addRes } = await api.post(
      CART_ITEM,
      {
        productVariantId: cartItem.variant?.productVariantId,
        addOnForCartItemID: cartItem.variant?.productVariantId,
        quantity: cartItem.quantity,
      },
      {
        headers,
      }
    )
    if (addRes.data.succeeded) return addRes
    else throw Error(addRes.data.message)
  } catch (e: any) {
    if (e.response?.status === 401) {
      await handleUnauthorizedRequest()
    } else {
      const error = e.response?.data?.Error || e.response?.data?.error || e
      if (error && error.ErrorContextItems) {
        const [item] = error.ErrorContextItems
        const [message] = item.Messages
        throw Error(message)
      } else if (error) {
        throw Error(error.Message || error.message)
      }
    }
  }
}

export const updateCartItem = async (cartItem: CartItem): Promise<any> => {
  try {
    const headers = await getHeaders()
    const { data } = await api.put(
      `${CART_ITEM}/${cartItem.cartItemId}`,
      {
        cartItemId: cartItem.cartItemApiInfo.cartItemId,
        productVariantId: cartItem.cartItemApiInfo.productVariantId,
        productVariantName: cartItem.cartItemApiInfo.productVariantName,
        productVariantType: cartItem.cartItemApiInfo.productVariantType,
        priceInCart: cartItem.cartItemApiInfo.priceInCart,
        quantity: cartItem.cartItemApiInfo.quantity,
      },
      {
        headers,
      }
    )
    if (data.data.succeeded) return data
    else throw Error(data.message)
  } catch (e: any) {
    const { Error: error } = e.response.data || e
    if (error && error.ErrorContextItems) {
      const [item] = error.ErrorContextItems
      const [message] = item.Messages
      throw Error(message || error.Message)
    } else if (error) {
      throw Error(error.Message || error.message)
    }
  }
}

export const deleteCartItem = async (cartItem: CartItem): Promise<any> => {
  try {
    const { data } =
      (await api.delete(`${CART_ITEM}/${cartItem.cartItemId}`, {
        headers: await getHeaders(),
      })) || {}
    if (data.data.succeeded) return data
    else throw Error(data.message)
  } catch (e: any) {
    const error = e.response?.data?.Error || e.response?.data?.error || e
    if (error && error.ErrorContextItems) {
      const [item] = error.ErrorContextItems
      const [message] = item.Messages
      throw Error(message || error.Message)
    } else if (error) {
      throw Error(error.Message || error.message)
    }
  }
}

export const beginCheckout = async (): Promise<any> => {
  const token = await accessTokenCache.get<string>()
  if (!token) throw Error('must be logged in')
  else {
    const { data } = await api.post(
      BEGIN_CHECKOUT,
      {},
      {
        headers: await getHeaders(),
      }
    )
    return data
  }
}
