/* eslint-disable @typescript-eslint/no-explicit-any */
import { HELP_TOPICS_URL } from '../../constants'
import { api, getHeaders } from '../../services/webstoreApiRequests'
import { HelpTopic } from '../../types'

export const getHelpTopics = async (): Promise<HelpTopic[]> => {
  try {
    const headers = await getHeaders()
    const { data } = (
      await api.get(HELP_TOPICS_URL, {
        headers,
      })
    ).data
    return data
  } catch (e) {
    throw Error((e as Error).message)
  }
}
