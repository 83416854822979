/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { defaultCookieProperties, DEFAULT_BOOTSTRAP, DEFAULT_SETTINGS, DEFAULT_TENANT } from '../../constants'
import { getBootstrap } from '../../services'
import { appSettingsCache, clearResCache } from '../../services/cache'
import { RootState } from '../../store'
import { Bootstrap, Settings } from '../../types'
import { getCookie, setCookie } from '../../utils'
import { fetchCategories, fetchPromoCategories } from '../categories/categorySlice'

type AppConfigStateType = {
  isError: boolean
  isBootstrapLatest: boolean
  isBootstrapLoading: boolean
  isBootstrapLoaded: boolean
  isSettingsLoading: boolean
  isSettingsLoaded: boolean
  bootstrap: Bootstrap
  settings: Settings
  cookieBannerValue: { isSet: boolean }
}

const COOKIE_NAME = `${DEFAULT_TENANT}.onthehub`

const initialState: AppConfigStateType = {
  isError: false,
  isBootstrapLoading: false,
  isBootstrapLatest: false, // Indicates that we have successfully fetched the latest bootstrap to replace the cached one
  isBootstrapLoaded: false,
  isSettingsLoading: false,
  isSettingsLoaded: false,
  bootstrap: DEFAULT_BOOTSTRAP,
  settings: DEFAULT_SETTINGS,
  cookieBannerValue: (getCookie(COOKIE_NAME) as { isSet: boolean }) || defaultCookieProperties.cookieValue,
}

// SETTINGS
export const updateSettings = createAsyncThunk('settings/update', async (s: Settings, thunkAPI) => {
  try {
    if (window.indexedDB) await clearResCache()

    await appSettingsCache.set(s)
    return s
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchBootstrap = createAsyncThunk('settings/getBootstrap', async (_, thunkAPI) => {
  try {
    const pre = thunkAPI.getState() as RootState
    const { settings } = pre.appConfigReducer

    const bootstrap = await getBootstrap()

    const curLocSettings: Settings = settings || {
      currency: bootstrap?.currencySettings.default[0],
      locale: bootstrap?.localizationSettings.default[0],
    }
    await thunkAPI.dispatch(updateSettings(curLocSettings))

    await Promise.all([thunkAPI.dispatch(fetchCategories()), thunkAPI.dispatch(fetchPromoCategories())])

    return bootstrap
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// COOKIE BANNER
export const updateCookieBannerValue = createAsyncThunk('cookieBanner/update', async (v: boolean, thunkAPI) => {
  try {
    setCookie(COOKIE_NAME, { isSet: v })
    return v
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBootstrap.pending, (state) => {
        state.isBootstrapLoaded = false
        state.isBootstrapLoading = true
      })
      .addCase(fetchBootstrap.fulfilled, (state, action) => {
        state.isBootstrapLoading = false
        state.isBootstrapLoaded = true
        state.isBootstrapLatest = true
        if (action.payload) state.bootstrap = action.payload
      })
      .addCase(fetchBootstrap.rejected, (state, action) => {
        state.isBootstrapLoading = false
        state.isError = true
        state.isBootstrapLoaded = false
        throw new Error(action.payload as string)
      })
      .addCase(updateSettings.pending, (state) => {
        state.isSettingsLoading = true
        state.isSettingsLoaded = false
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.isSettingsLoaded = true
        state.isSettingsLoading = false
        state.settings = action.payload
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.isError = true
        state.isSettingsLoading = false
        state.isSettingsLoaded = false
        throw new Error(action.payload as string)
      })
      .addCase(updateCookieBannerValue.fulfilled, (state, action) => {
        state.cookieBannerValue.isSet = action.payload
      })
      .addCase(updateCookieBannerValue.rejected, (state, action) => {
        state.isError = true
        throw new Error(action.payload as string)
      })
  },
})

export const { reset } = appConfigSlice.actions
export default appConfigSlice.reducer
