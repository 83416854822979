import {
  SITECATEGORIES_DATA_ANALYSIS,
  SITECATEGORIES_PHOTO_VIDEO_DESIGN,
  SITECATEGORIES_SECURITY_UTILITIES,
  SITECATEGORIES_BUSINESS_OFFICE,
  SITECATEGORIES_BOOKS,
} from '../constants'
export const helpTopicsIconsMap = {}

export const categoriesImagesMap: { [key: string]: string } = {
  [SITECATEGORIES_DATA_ANALYSIS?.toLocaleLowerCase() as string]: 'data_analysis',
  [SITECATEGORIES_PHOTO_VIDEO_DESIGN?.toLocaleLowerCase() as string]: 'photo_video_design',
  [SITECATEGORIES_SECURITY_UTILITIES?.toLocaleLowerCase() as string]: 'security_utilities',
  [SITECATEGORIES_BUSINESS_OFFICE?.toLocaleLowerCase() as string]: 'business_office',
  [SITECATEGORIES_BOOKS?.toLocaleLowerCase() as string]: 'books',
}
