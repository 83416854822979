/* eslint-disable no-console */
import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import appConfigReducer from '../features/appConfig/appConfigSlice'
import blogReducer from '../features/blogs/blogSlice'
import cartReducer from '../features/cart/cartSlice'
import categoryReducer from '../features/categories/categorySlice'
import helpTopicReducer from '../features/helpTopics/helpTopicSlice'
import homeBannerReducer from '../features/homeBanners/homeBannerSlice'
import orderReducer from '../features/orders/orderSlice'
import productReducer from '../features/products/productSlice'
import userReducer from '../features/users/userSlice'
import { appSettingsCache, resCache, userDataStore } from '../services/cache'

const appSettingsPersistConfig = {
  key: 'app-settings',
  storage: appSettingsCache.backingCache,
  whitelist: ['settings', 'bootstrap', 'isBootstrapLoaded'],
}

const cartPersistConfig = {
  key: 'cart',
  storage: userDataStore,
  whitelist: ['cartItems', 'accessGuarantee', 'userOptOutEAG'],
}

const categoriesPersistConfig = {
  key: 'categories',
  storage: resCache,
  whitelist: ['categories', 'promoCategories'],
}

const helpTopicPersistConfig = {
  key: 'help-topics',
  storage: resCache,
  whitelist: ['helpTopics'],
}

const blogsPersistConfig = {
  key: 'blogs',
  storage: resCache,
  whitelist: ['blogs'],
}

const homeBannersPersistConfig = {
  key: 'home-banners',
  storage: resCache,
  whitelist: ['homeBanners'],
}

const orderPersistConfig = {
  key: 'orders',
  storage: userDataStore,
  whitelist: ['orders'],
}

const productPersistConfig = {
  key: 'products',
  storage: resCache,
  whitelist: ['products'],
}

const userPersistConfig = {
  key: 'user',
  storage: userDataStore,
  whitelist: ['user', 'verifications', 'authenticated'],
}
const persistedAppConfigReducer = persistReducer(appSettingsPersistConfig, appConfigReducer)
const persistedBlogReducer = persistReducer(blogsPersistConfig, blogReducer)
const persistedCartReducer = persistReducer(cartPersistConfig, cartReducer)
const persistedCategoryReducer = persistReducer(categoriesPersistConfig, categoryReducer)
const persistedHomeBannersReducer = persistReducer(homeBannersPersistConfig, homeBannerReducer)
const persistedHelpTopicReducer = persistReducer(helpTopicPersistConfig, helpTopicReducer)
const persistedOrderReducer = persistReducer(orderPersistConfig, orderReducer)
const persistedProductReducer = persistReducer(productPersistConfig, productReducer)
const persistedUserReducer = persistReducer(userPersistConfig, userReducer)

const rootReducer = combineReducers({
  appConfigReducer: persistedAppConfigReducer,
  blogReducer: persistedBlogReducer,
  cartReducer: persistedCartReducer,
  categoryReducer: persistedCategoryReducer,
  helpTopicReducer: persistedHelpTopicReducer,
  homeBannerReducer: persistedHomeBannersReducer,
  orderReducer: persistedOrderReducer,
  productReducer: persistedProductReducer,
  userReducer: persistedUserReducer,
})

export default rootReducer
