/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = <Params extends any[]>(func: (...args: Params) => any, timeout: number): ((...args: Params) => void) => {
  let timer: number | NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer as number)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
