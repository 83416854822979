/* eslint-disable import/no-named-as-default */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import cssVars from 'css-vars-ponyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { HelmetProvider } from 'react-helmet-async'
import smoothscroll from 'smoothscroll-polyfill'

import { GA_EVENTS } from './constants'
import './utils/i18n/config'
import './index.css'
import './fonts/icon/feather.css'
import './remote-content.css'
import Routing from './routes'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react').default
  axe(React, ReactDOM, 1000)
}

smoothscroll.polyfill()
cssVars({
  // Targets
  rootElement: document,
  shadowDOM: false,

  // Sources
  include: 'link[rel=stylesheet],style',
  exclude: '',
  variables: {},

  // Options
  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: true,
})

const isDebug = !(process.env.NODE_ENV === 'production')

ReactGA.initialize('G-YRC301RVC3', {
  gaOptions: {
    isDebug: isDebug,
  },
  gtagOptions: {
    custom_map: {
      dimension1: GA_EVENTS.TENANT,
      dimension2: GA_EVENTS.PROGRAM,
    },
  },
})

ReactDOM.render(
  <HelmetProvider>
    {/* <React.StrictMode> */}
    <Routing />
    {/* </React.StrictMode> */}
  </HelmetProvider>,
  document.getElementById('root')
)
