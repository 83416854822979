import { env } from '../utils/env'

export const API = env('REACT_APP_API_URL')

export const PRODUCTS_WITH_VARIANTS_URL = '/products/consolidated'
export const CART_ITEM_COLLECTION = '/cart'
export const CART_ITEM = '/cart/item'
export const BEGIN_CHECKOUT = '/cart/BeginCheckout'
export const BOOTSTRAP_URL = '/tenant/bootstrap'
export const USER_PROFILE_URL = '/user/profile'
export const SITE_CATEGORIES_URL = '/categories/sitecategories'
export const PRODUCT_CATEGORIES_URL = '/categories/productcategories'
export const HELP_TOPICS_URL = '/tenant/help'
export const ORDERS_URL = '/orders'
