import { AnyAction, AsyncThunkAction, configureStore, Store, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore } from 'redux-persist'

import reducers from './rootReducer'

// Get the root state's type from reducers
export type RootState = ReturnType<typeof reducers>

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>

// Create a type for store using RootState and Thunk enabled dispatch
export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
  dispatch: AppThunkDispatch
}

//4. create the store with your custom AppStore
export const store: AppStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

export const useAppDispatch = () => useDispatch<AppThunkDispatch>()
export const useAppDispatchThenUnwrap = () => {
  const dispatch = useAppDispatch()
  return <R>(action: AsyncThunkAction<R, unknown, object>) => dispatch(action).then(unwrapResult)
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
