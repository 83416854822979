export class TokenSet {
  accessToken: string
  expiresIn: number
  idToken: string
  scope: string
  tokenType: string
  constructor(payload: TokenPayload | undefined | null) {
    if (!payload) throw new Error('Token payload cannot be undefined/null')

    this.accessToken = payload.access_token
    this.expiresIn = payload.expires_in
    this.idToken = payload.id_token
    this.scope = payload.scope
    this.tokenType = payload.token_type
  }
}

export type TokenPayload = {
  access_token: string
  expires_in: number
  id_token: string
  scope: string
  token_type: string
}

export type PKCE = {
  verifier: string
  state: string
}
