import { ReactElement, useEffect, FC, useState, useCallback } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { BigPulseLoader } from '../components/loaders'
import { fetchUser } from '../features/users/userSlice'
import { accessTokenCache } from '../services'
import { useAppDispatch, useAppSelector } from '../store'

export type ProtectedRouteProps = RouteProps

const LoadingHandler: FC = (): ReactElement => {
  const [resolve, setResolve] = useState<boolean>(false)
  useEffect(() => {
    const timeout = setTimeout(() => setResolve(true), 3000)
    return () => clearTimeout(timeout)
  }, [setResolve])

  return resolve ? (
    <Redirect to={{ pathname: '/signin/code', search: `?source=${encodeURIComponent(window.location.pathname)}` }} />
  ) : (
    <BigPulseLoader />
  )
}
const ProtectedRoute = ({ ...routeProps }: ProtectedRouteProps): ReactElement => {
  const dispatch = useAppDispatch()

  const { authenticated, user } = useAppSelector((state) => state.userReducer)
  const { isBootstrapLoaded } = useAppSelector((state) => state.appConfigReducer)

  const checkAuth = useCallback(async () => {
    const t = await accessTokenCache.get<string>()
    if (t && !user && isBootstrapLoaded) {
      await dispatch(fetchUser())
    }
  }, [dispatch, isBootstrapLoaded, user])

  useEffect(() => {
    Promise.all([checkAuth()])
  }, [checkAuth])

  return authenticated && user ? <Route {...routeProps} /> : <LoadingHandler />
}

export default ProtectedRoute
