export const redirectTo = (url: string, newTab = false): void => {
  const a = document.createElement('a')
  a.href = url

  if (newTab) {
    a.rel = 'opener'
    a.target = '_blank'
  }

  a.click()
}
