/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { HelpTopic } from '../../types'
import { getHelpTopics } from './helpTopicService'

const helpTopics: HelpTopic[] = [] // Data will come from localForage through react-persist

const initialState = {
  helpTopics: helpTopics,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get helpTopics
export const fetchHelpTopics = createAsyncThunk('helpTopics/getAll', async (_, thunkAPI) => {
  try {
    const getHelpTopicsWithRetry = wrapWithRetryAsync(getHelpTopics)
    const helpTopicsRes: HelpTopic[] = await getHelpTopicsWithRetry()

    return helpTopicsRes
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const helpTopicSlice = createSlice({
  name: 'helpTopics',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHelpTopics.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHelpTopics.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.helpTopics = action.payload
      })
      .addCase(fetchHelpTopics.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.helpTopics = []
        state.message = action.payload as string
        throw new Error(state.message as string)
      })
  },
})

export const { reset } = helpTopicSlice.actions
export default helpTopicSlice.reducer
