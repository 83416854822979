import axios from 'axios'

import { FIND_MY_SCHOOL_API } from '../constants'
/* eslint-disable @typescript-eslint/no-explicit-any */
const findMySchool = axios.create({
  baseURL: FIND_MY_SCHOOL_API,
  timeout: 3000,
})

export const getCountries = async (): Promise<any> => findMySchool.get('/countries')

export const getProvinces = async (countryCode: string): Promise<any> => findMySchool.get(`/provinces?countryCode=${countryCode}`)

export const getInstitutions = async (countryCode: string, provinceCode?: string): Promise<any> =>
  findMySchool.get(`/institutions?countryCode=${countryCode}${provinceCode ? '&ProvinceCode=' + provinceCode : ''}`)

export const getInstitutionWebStore = async (institutionUuid: string, country: string): Promise<any> =>
  findMySchool.get(`/institutions/${institutionUuid}/webstores?countryCode=${country}`)
