/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { getBlogs } from '../../services/wordpressApiServices'
import { Blog } from '../../types'

const blogs: Blog[] = [] // Data will come from localForage through react-persist

const initialState = {
  blogs: blogs,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get blogs
export const fetchBlogs = createAsyncThunk('blogs/getAll', async (_, thunkAPI) => {
  try {
    const getBlogsWithRetry = wrapWithRetryAsync(getBlogs)
    const blogsRes: Blog[] = await getBlogsWithRetry()

    return blogsRes
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.blogs = action.payload
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload as string
        state.blogs = []
      })
  },
})

export const { reset } = blogSlice.actions
export default blogSlice.reducer
