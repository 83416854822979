export const getCookie = <T>(cName: string): T | undefined | null => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const obj: { [key: string]: string } = {}
  decodedCookie.split(';').forEach((c) => {
    const [key, value] = c.split('=')
    obj[key.trim()] = value
  })
  return obj[cName] && JSON.parse(obj[cName])
}

export const setCookie = <T>(cName: string, cValue: T, expiryInMinutes?: number | undefined): void => {
  const d = new Date()
  if (expiryInMinutes) d.setTime(d.getTime() + expiryInMinutes * 60 * 60 * 1000)

  const expiry = expiryInMinutes && `expires=${d.toUTCString()}`
  document.cookie = `${cName}=${JSON.stringify(cValue)};${expiry ? expiry + ';' : ''}path=/`
}

export const removeCookie = (cName: string) => (document.cookie = `${cName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`)

export const checkCookie = (cookie: string): boolean => document.cookie.includes(cookie)
