/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { UserOrder } from '../../types'
import { getOrdersList } from './orderService'

// Get orders
const orders: UserOrder[] = []

const initialState = {
  orders: orders,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get orders
export const fetchOrders = createAsyncThunk('orders/getAll', async (_, thunkAPI) => {
  try {
    const userOrders = await getOrdersList()

    return userOrders
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const clearOrders = createAsyncThunk('orders/clear', async (_, thunkAPI) => {
  try {
    reset()
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = action.payload || []
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
      .addCase(clearOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(clearOrders.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = []
      })
      .addCase(clearOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
  },
})

export const { reset } = orderSlice.actions
export default orderSlice.reducer
