import { PRODUCTS_WITH_VARIANTS_URL } from '../../constants'
import { api, getHeaders } from '../../services/webstoreApiRequests'
import { ProductWithVariants } from '../../types'

export const getApiProducts = async (): Promise<ProductWithVariants[] | undefined> => {
  try {
    const headers = await getHeaders()
    const { data } = await api.get(PRODUCTS_WITH_VARIANTS_URL, {
      headers,
    })

    return data.data
  } catch (e) {
    throw Error(e as string)
  }
}
