/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// import folders from './folders.json'
// export const resources = folders.reduce((pre, curr) => ({ ...pre, [curr]: { translation: require(`./${curr}/translation.json`) } }), {})

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'localStorage'],
      lookupLocalStorage: 'locale',
      lookupCookie: 'locale',
    },
    resources: { en: { translation: require('./en/translation.json') } },
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
