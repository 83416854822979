import { ORDERS_URL } from '../../constants'
import { api, getHeaders, accessTokenCache } from '../../services'
import { UserOrder } from '../../types'

type OrderListRes = {
  shoppingInstanceId: string
  statusCode: number
  message: string
  data: UserOrder[]
}

export const getOrdersList = async (): Promise<UserOrder[]> => {
  try {
    const token = await accessTokenCache.get<string>()
    if (!token) throw Error('must be logged in')

    const headers = await getHeaders()
    const { data }: OrderListRes = (
      await api.get(ORDERS_URL, {
        headers,
      })
    ).data
    return data
  } catch (e) {
    throw Error((e as Error).message)
  }
}
