/* eslint-disable no-console */
import {
  SITECATEGORIES_BOOKS,
  SITECATEGORIES_BOOKS_PRODUCER_LIST,
  SITECATEGORIES_BUSINESS_OFFICE,
  SITECATEGORIES_BUSINESS_OFFICE_PRODUCER_LIST,
  SITECATEGORIES_DATA_ANALYSIS,
  SITECATEGORIES_DATA_ANALYSIS_PRODUCER_LIST,
  SITECATEGORIES_PHOTO_VIDEO_DESIGN,
  SITECATEGORIES_PHOTO_VIDEO_DESIGN_PRODUCER_LIST,
  SITECATEGORIES_SECURITY_UTILITIES,
  SITECATEGORIES_SECURITY_UTILITIES_PRODUCER_LIST,
} from '../constants'
import { SiteCategory } from '../types'
import { convertToLowerCase } from './array'
import { categoriesImagesMap } from './imageMappers'
import { convertDictionaryKeysToLowerCase } from './object'

export const getCategoryQuery = (name: string): string => {
  return name.toLowerCase().replace(/ &/g, '').replace(/,/g, '')
}

export const categoriesProducerListSorter: { [key: string]: string | undefined } = {
  [SITECATEGORIES_DATA_ANALYSIS as string]: SITECATEGORIES_DATA_ANALYSIS_PRODUCER_LIST,
  [SITECATEGORIES_PHOTO_VIDEO_DESIGN as string]: SITECATEGORIES_PHOTO_VIDEO_DESIGN_PRODUCER_LIST,
  [SITECATEGORIES_SECURITY_UTILITIES as string]: SITECATEGORIES_SECURITY_UTILITIES_PRODUCER_LIST,
  [SITECATEGORIES_BUSINESS_OFFICE as string]: SITECATEGORIES_BUSINESS_OFFICE_PRODUCER_LIST,
  [SITECATEGORIES_BOOKS as string]: SITECATEGORIES_BOOKS_PRODUCER_LIST,
}

/**
 * Sort category producer list according to which are enabled in the environment.
 *
 * @param category item for which the producer list needs to be sorted
 * @returns category with sorted producer list
 */
const sortProducerListByEnv = (category: SiteCategory): SiteCategory => {
  const categoryProducerListSorter = convertDictionaryKeysToLowerCase(categoriesProducerListSorter)
  const envProducerList = categoryProducerListSorter[category.siteCategoryId.toLocaleLowerCase()]?.split('|')
  if (envProducerList) {
    const lowerCaseProducerList = convertToLowerCase(category.producerList)
    const lowerCaseEnvProducerList = convertToLowerCase(envProducerList)
    //filter env producers that are not in the list of producers from the API
    const filteredEnvProducerList = envProducerList.filter((pl) => lowerCaseProducerList.includes(pl.toLocaleLowerCase()))
    // filter category producer list thar are in env producer list
    const filteredCategoryNonENVProducerList = category.producerList.filter(
      (cpl) => !lowerCaseEnvProducerList.includes(cpl.toLocaleLowerCase())
    )
    category.producerList = filteredEnvProducerList.concat(filteredCategoryNonENVProducerList)
  }
  return category
}

/**
 * Filter and sort categories according to which are enabled in the environment.
 *
 * @param categories category items (usually from the API)
 * @returns Filtered and sorted categories
 */
export const filterCategoriesByEnv = (categories: SiteCategory[]): SiteCategory[] => {
  const catsIds = Object.keys(categoriesImagesMap)
  return [...categories]
    .filter((c) => catsIds.includes(c.siteCategoryId.toLocaleLowerCase()))
    .map(sortProducerListByEnv)
    .sort((a, b) => a.sequence - b.sequence)
}
