/* eslint-disable no-console */
import { t } from 'i18next'

import {
  ACCESS_GUARANTEE_PRODUCT_TYPE,
  ADD_EXTENDED_ACCESS_GUARANTEE,
  EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE,
  NOT_APPLICABLE_STATUS,
} from '../constants'
import { ApiCartItem, CartItem, INCLUSION_TYPE_OPTIONS, ProductVariantDependency, ProductWithVariants } from '../types'
import { findProductByVariant, findVariant } from './products'

export const isAccessGuarantee = (variantType: string): boolean =>
  variantType === EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE || variantType === ACCESS_GUARANTEE_PRODUCT_TYPE

export const constructCartItem = (fetchedProducts: ProductWithVariants[], apiCartItem: ApiCartItem): CartItem => {
  const cartProduct = isAccessGuarantee(apiCartItem.productVariantType)
    ? fetchedProducts.find(
        (p) =>
          !!p.variants.find((v) => {
            return isAccessGuarantee(v.productVariantType)
          })
      )
    : findProductByVariant(fetchedProducts, apiCartItem.productVariantId)

  const cartVariant = isAccessGuarantee(apiCartItem.productVariantType)
    ? cartProduct && cartProduct.variants[0]
    : findVariant(fetchedProducts, apiCartItem.productVariantId)

  return {
    product: cartProduct,
    variant: cartVariant,
    quantity: apiCartItem.quantity,
    cartItemId: apiCartItem.cartItemId,
    cartItemApiInfo: apiCartItem,
  }
}

const includeVariantIDs = (cartItem: CartItem, ...inclusionTypes: INCLUSION_TYPE_OPTIONS[]): string[] => {
  return cartItem.variant?.dependencies?.reduce((ac: string[], dependency: ProductVariantDependency) => {
    return inclusionTypes.includes(dependency.inclusionType) ? [...ac, ...dependency.productVariantIds] : [...ac]
  }, []) as string[]
}

export const sortCart = (items: CartItem[]): CartItem[] => {
  items.sort((a, b) => a.cartItemApiInfo.priceInCart - b.cartItemApiInfo.priceInCart)

  //add accessGuarantee to the end of the cart
  const accessGuaranteeIndex = items.findIndex((item) => item.cartItemApiInfo.productVariantType.endsWith(ACCESS_GUARANTEE_PRODUCT_TYPE))
  if (accessGuaranteeIndex >= 0) {
    const accessGuaranteeItem = items[accessGuaranteeIndex]
    items.splice(accessGuaranteeIndex, 1)
    if (accessGuaranteeItem) {
      items.push(accessGuaranteeItem)
    }
  }

  return items
}

/**
 * Check if there are items in the cart that can require access Guarantee.
 *
 * @param cartItems Items currently in the cart
 * @returns Whether there's at least one item on which access Guarantee is applicable to
 */
export const isAccessGuaranteeApplicableToCart = (cartItems: CartItem[]): boolean => {
  const isAGApplicableToCart = cartItems.some((ci) => isAccessGuaranteeApplicableToItem(ci))

  return isAGApplicableToCart
}

/**
 * Check if the given item from the cart can require access Guarantee.
 *
 * @param cartItem Item currently in the cart
 * @returns Whether access Guarantee is applicable to the item
 */
export const isAccessGuaranteeApplicableToItem = (cartItem: CartItem): boolean => {
  return (
    ADD_EXTENDED_ACCESS_GUARANTEE &&
    !cartItem.cartItemApiInfo.productVariantType.endsWith(ACCESS_GUARANTEE_PRODUCT_TYPE) &&
    !cartItem.cartItemApiInfo.isAccessGuaranteeNotApplicable &&
    cartItem.cartItemApiInfo.accessGuaranteeStatus !== NOT_APPLICABLE_STATUS
  )
}

export const getAccessGuaranteePeriod = (isAccessGuaranteeAppliedToCart: boolean): string => {
  return isAccessGuaranteeAppliedToCart
    ? t('Cart_accessGuaranteePeriodExtended', { count: 24 })
    : t('Cart_accessGuaranteePeriodBasic', { count: 30 })
}

export const getAddOnsDependenciesFromCart = (
  cartItems: CartItem[],
  cartItem: CartItem,
  ...inclusionTypes: INCLUSION_TYPE_OPTIONS[]
): string[] => {
  if (!cartItem.cartItemApiInfo.hasAddons) return []

  // Set defaults if no inclusionTypes provided
  if (!inclusionTypes || !inclusionTypes.length) inclusionTypes = [INCLUSION_TYPE_OPTIONS.OPTIONAL, INCLUSION_TYPE_OPTIONS.REQUIRED]

  const cartItemDependencies = includeVariantIDs(cartItem, ...inclusionTypes)
  const cartItemsDependencies = cartItems.reduce((acc: string[], ci: CartItem) => {
    if (ci.cartItemApiInfo.productVariantId !== cartItem.cartItemApiInfo.productVariantId) {
      const newVariantIds = includeVariantIDs(ci, ...inclusionTypes)
      return [...acc, ...newVariantIds]
    }
    return [...acc]
  }, [])

  return cartItemDependencies?.filter((ci: string) => !cartItemsDependencies.includes(ci))
}

export const filterCart = (cartItems: CartItem[], cartItem: CartItem, productVariantDependency: string[]): CartItem[] => {
  const updatedcartItems = [...cartItems].filter(
    (item) =>
      item.cartItemId !== cartItem.cartItemId &&
      item.cartItemApiInfo.addOnForProductVariantId !== cartItem.cartItemApiInfo.productVariantId &&
      !productVariantDependency?.includes(item.cartItemApiInfo.productVariantId)
  )

  return updatedcartItems
}
