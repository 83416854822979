import { Country, Currency, UserGroup, Uuid } from '../types'

export enum INCLUSION_TYPE_OPTIONS {
  REQUIRED = 'MustInclude',
  OPTIONAL = 'OptionallyInclude',
  PROHIBITED = 'MustNotInclude',
}

export type ProductDeliveryType =
  | 'Download'
  | 'CustomDelivery'
  | 'MailOrder'
  | 'PickUp'
  | 'download'
  | 'mail_order'
  | 'pickup'
  | 'custom_delivery'

export type ProductDescription = {
  short: string | null
  long: string | null
  notes: string | null
}

export type ProductSupportedPlatforms = 'windows' | 'macOs' | 'linux' | 'Mac' | 'Windows'

export type ProductMediaUrl = {
  alt: string | null
  url: string | null
  displayIntent: 'card_image' | 'thumbnail' | 'slide_item' | 'media_card' | 'Thumbnail' | 'SlideItem' | 'CardImage' | null
  mediaType: 'Image' | 'Video' | null
}

export type ProductSubscriptionConfig = {
  expiresAfter: {
    fixedExpiryDate: string | null
    variableExpiryConfig: {
      duration: number
      durationType: number
    }
  } | null
  externalConfig: {
    externalService: Uuid
    externalSKU: string
  } | null
  isRenewable: boolean
  supportsNonRenewableVersion: boolean
}
export type ProductPurchaseConstraints = {
  countryRestrictions: {
    restrictionType: 'MustInclude' | 'MustNotInclude' | null
    countries: Country[]
  }
  isExternalSubscription: boolean
  isSubscription: boolean
  name: string | null
  subscriptionConfig?: ProductSubscriptionConfig | null
}

export type ProductPaymentMethods = 'CreditCard' | 'PayPal' | 'creditcard' | 'paypal' | 'Credit Card' | null

export type ProductPromotions = {
  savings: number | null
  promotionName: string | null
  promotionNotes: string | null
  appliesToOfferings: Uuid[] | null
  promotionType: 0 | 1 | 2 | null
}

export type ProductAsLowAs = {
  currencyType: Currency
  price: number
  msrp: number
  promotions: ProductPromotions[] | null
}

export type ProductConsolidatedVariantInfo = {
  availableTo: UserGroup[]
  deliveryTypes: ProductDeliveryType[] | null
  producer: string
  supportedPlatforms: ProductSupportedPlatforms[] | null
}

export type ProductCategory = {
  productCategoryId: Uuid
  name: string
}

export type Category = {
  name: string
  parentSiteCategoryId: Uuid | null
  producerList: string[] | null
  productCategories?: ProductCategory[]
  sequence: number
  siteCategoryId: Uuid
}

export type ProductTag = string

export interface ProductWithVariants {
  asLowAs: ProductAsLowAs
  consolidatedVariantInfo: ProductConsolidatedVariantInfo
  description: ProductDescription
  mediaUrls: ProductMediaUrl[] | null
  name: string
  productCategories: Uuid[] | null
  productId: Uuid
  relatedProducts: Uuid[] | null
  siteCategories: Uuid[] | null
  systemRequirements: string | null
  tags: ProductTag[] | null
  variants: ProductVariant[]
}

export type ProductAvailability = {
  restrictionType: 'MustInclude' | null
  eligibility: UserGroup[]
}

export type ProductVariantDependency = {
  inclusionType: INCLUSION_TYPE_OPTIONS
  productVariantIds: Uuid[]
}

export interface ProductVariant {
  availableTo: ProductAvailability[]
  deliveryType: 'Download' | 'CustomDelivery'
  dependencies: ProductVariantDependency[]
  description: ProductDescription
  externalUrl: string | null
  externalUrlLabel: string | null
  platforms: ProductSupportedPlatforms[] | null
  pricing: ProductAsLowAs
  producer: string | null
  productID: Uuid
  productVariantId: Uuid
  productVariantType: 'Offering' | 'AccessGuarantee' | 'ExtendedAccessGuarantee' | 'AddOn'
  purchaseConstraints: ProductPurchaseConstraints
  supportedPaymentMethods: ProductPaymentMethods[]
}
