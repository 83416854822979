import { env } from '../utils/env'

export const ADD_EXTENDED_ACCESS_GUARANTEE = true
export const ACCESS_GUARANTEE_PRODUCT_TYPE = 'AccessGuarantee'
export const EXTENDED_ACCESS_GUARANTEE_PRODUCT_TYPE = 'ExtendedAccessGuarantee'
export const OFFERING_PRODUCT_TYPE = 'Offering'
export const OPTIONALLY_INCLUDE_INCLUSION_TYPE = 'OptionallyInclude'
export const ADD_ON_PRODUCT_TYPE = 'AddOn'
export const NOT_APPLICABLE_STATUS = 'NotApplicable'
export const DEFAULT_POPUP_DURATION = 5
export const SPA_URL = env('REACT_APP_SPA_URL')
