import { ProductWithVariants, UserGroup } from '../types'
import { removeDuplicate } from './array'

export const eligibilitiesMapper = (eligibilities: string[], c?: boolean): UserGroup[] => {
  const mapper: { [key: string]: UserGroup[] } = {
    'academic users': ['Students', 'Faculty', 'Staff'],
    anyone: ['Students', 'Faculty', 'Staff'],
    'faculty/staff': ['Faculty', 'Staff'],
    students: ['Students'],
    faculty: ['Faculty'],
    staff: ['Staff'],
  }
  return removeDuplicate<UserGroup>(eligibilities.reduce((pre, curr) => [...pre, ...(mapper[curr.toLowerCase()] || [])], [] as UserGroup[]))
}

export const eligibilityCleanUp = (products: ProductWithVariants[]): ProductWithVariants[] =>
  products
    .map((p: ProductWithVariants) => ({
      ...p,
      consolidatedVariantInfo: {
        ...p.consolidatedVariantInfo,
        availableTo: eligibilitiesMapper(p.consolidatedVariantInfo.availableTo.length ? p.consolidatedVariantInfo.availableTo : ['Anyone']),
      },
    }))
    .filter((p: ProductWithVariants) => !!p.consolidatedVariantInfo.availableTo.length)
