import { Bootstrap } from '../types'

export const DEFAULT_TENANT = 'estore'

export const DEFAULT_BOOTSTRAP: Bootstrap = {
  countrySettings: { supported: null },
  currencySettings: {
    default: [{ currencyID: 2, currencyName: 'US Dollars', currencyAbbreviation: 'USD' }],
    supported: [],
  },
  localizationSettings: {
    default: [
      {
        languageID: 1,
        language: 'en-us',
        locale: 'English (United States)',
      },
    ],
    supported: [],
  },
  isOpenRegistration: true,
  displayName: 'OnTheHub',
  lookSettings: { logoID: 'b4508715-1050-4b81-8d8b-de9cc7e3afbd' },
  tenantName: DEFAULT_TENANT,
}

export const DEFAULT_SETTINGS = {
  currency: DEFAULT_BOOTSTRAP.currencySettings.default[0],
  locale: DEFAULT_BOOTSTRAP.localizationSettings.default[0],
}
