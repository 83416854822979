/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { DEFAULT_PROMO_CATEGORIES } from '../../constants'
import { getCategories, getSpecialCategories } from '../../services'
import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { PromotionalCategories, SiteCategory } from '../../types'
import { filterCategoriesByEnv } from '../../utils'

type AppConfigStateType = {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  categories: SiteCategory[]
  promoCategories: PromotionalCategories
}

const initialState: AppConfigStateType = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  categories: [],
  promoCategories: DEFAULT_PROMO_CATEGORIES,
}

export const fetchPromoCategories = createAsyncThunk('categories/getAllPromo', async (_, thunkAPI) => {
  try {
    const getPromoCategoriesWithRetry = wrapWithRetryAsync(getSpecialCategories)
    const promoCategoriesRes = await getPromoCategoriesWithRetry()
    return promoCategoriesRes
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchCategories = createAsyncThunk('categories/getAll', async (_, thunkAPI) => {
  try {
    const getCategoriesWithRetry = wrapWithRetryAsync(getCategories)
    const categories = filterCategoriesByEnv(await getCategoriesWithRetry())
    return categories
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const categorySlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = action.payload
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
      .addCase(fetchPromoCategories.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPromoCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        if (action.payload) state.promoCategories = action.payload
      })
      .addCase(fetchPromoCategories.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        throw new Error(action.payload as string)
      })
  },
})

export const { reset } = categorySlice.actions
export default categorySlice.reducer
