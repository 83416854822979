export const snackPosition = {
  topLeft: 'snackbar-top snackbar-left',
  topRight: 'snackbar-top snackbar-right',
  bottomLeft: 'snackbar-bottom snackbar-left',
  bottomRight: 'snackbar-bottom snackbar-right',
}

export const getMargin = (existingPortals: NodeListOf<Element>): number => {
  if (existingPortals.length) {
    const amount = existingPortals.length
    const singleHeight = Number(getComputedStyle(existingPortals[0]).height.replace(/[^-0-9]/g, ''))
    return singleHeight * amount
  }
  return 0
}
