import { ProductVariant, ProductWithVariants, PromotionalCategories, PromotionalKeys } from '../types'
import { escapeSpecialRegExCharacters, SPECIAL_REGEX_CHARACTERS } from './string'

export const findVariant = (products: ProductWithVariants[], variantId: string): ProductVariant | undefined =>
  products.reduce(
    (prev: ProductVariant | undefined, curr: ProductWithVariants) => curr.variants.find((v) => v.productVariantId === variantId) || prev,
    undefined
  )

export const findVariantByProduct = (product: ProductWithVariants, variantId: string): ProductVariant | undefined =>
  product.variants.filter((v) => v.productVariantId === variantId)[0]
export const findProductByVariant = (products: ProductWithVariants[], variantId: string): ProductWithVariants | undefined =>
  products.find((product) => !!product.variants.find((variant) => variant.productVariantId === variantId))

export const findProductById = (products: ProductWithVariants[], productId: string): ProductWithVariants | undefined =>
  products.find((product) => product.productId === productId)

export const filterByPromoCategory = (
  products: ProductWithVariants[],
  promoCategories: PromotionalCategories,
  promoCategory: PromotionalKeys
): ProductWithVariants[] => {
  return products
    .filter((product: ProductWithVariants) => promoCategories[promoCategory].includes(product.productId))
    .sort(
      (a: ProductWithVariants, b: ProductWithVariants) =>
        promoCategories[promoCategory].indexOf(a.productId) - promoCategories[promoCategory].indexOf(b.productId)
    )
}

export const getProductsTotalByCategoryId = (products: ProductWithVariants[], categoryId: string): number => {
  return products.filter((p, i) => p.siteCategories?.includes(categoryId)).length
}

export const getOfferings = (products: ProductWithVariants[]): ProductWithVariants[] =>
  products
    .map((p: ProductWithVariants) => ({
      ...p,
      variants: p.variants.filter((v: ProductVariant) => v.productVariantType.toLowerCase() === 'offering'),
    }))
    .filter((p) => !!p.variants.length)

export const getOfferingsByName = (products: ProductWithVariants[], query: string) =>
  products.filter((product: ProductWithVariants) => {
    const source = product.name + product.description.short + product.consolidatedVariantInfo.producer
    const queryTokenizationRegEx = new RegExp(
      [
        '\\b([\\w\\d]+)\\b', // isolate words and digits
        '[:&/™®]', // isolate non-regex special characters
        `(\\\\[${escapeSpecialRegExCharacters(SPECIAL_REGEX_CHARACTERS.join(''))}]+)`, // isolate regex escaped special characters
      ].join('|'),
      'g'
    )
    return source.trim().match(
      new RegExp(
        `(${escapeSpecialRegExCharacters(query)
          .replace(queryTokenizationRegEx, '(?=.*($&))') // tokenize seach words
          .replaceAll(' ', '')})`, // remove spaces
        'gi'
      )
    )
  })
