/* eslint-disable no-console */
import axios from 'axios'

import { WORDPRESS_API } from '../constants'
import { HomepageBanner, PromotionalCategories, StaticPages } from '../types'
import { Blog } from '../types/blog'
import { apiCacheInvalidationFlagCache } from './cache'

export const wordpress = axios.create({
  baseURL: WORDPRESS_API,
})

export const getWordpressHeaders = async () => {
  const headerSet = {
    Accept: '*/*',
  }
  const cacheInvalid = await apiCacheInvalidationFlagCache.get()
  return cacheInvalid ? { ...headerSet, 'Cache-Control': `${cacheInvalid}` } : headerSet
}
// list of all the blogs
export const getBlogs = async (): Promise<Blog[]> => {
  // using the local .json
  // const { data } = await axios.get('/blogs.json')
  const { data } = await wordpress.get('/blogs', {
    headers: await getWordpressHeaders(),
  })

  return data
}

export const getSpecialCategories = async (): Promise<PromotionalCategories | undefined> => {
  const { data } = await wordpress.get('/special-category-products', {
    headers: await getWordpressHeaders(),
  })

  return data
}

export const getStaticPagesContent = async (): Promise<StaticPages | undefined> => {
  const { data } = await wordpress.get('/static-pages', {
    headers: await getWordpressHeaders(),
  })

  return data
}

export const getHomeBanners = async (): Promise<HomepageBanner[] | undefined> => {
  const { data } = (await wordpress.get('/home-page-banners', {
    headers: await getWordpressHeaders(),
  })) as { data: HomepageBanner[] }

  // eslint-disable-next-line prettier/prettier
  return data.sort((a, b) => a.position - b.position).map((b) => ({ ...b, htmlContent: b.htmlContent.replace(/\\/g, '') }))
}
