import { FC, ReactElement } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { ASSETS } from '../../../constants'
import { random } from '../../../utils/random'
import 'react-lazy-load-image-component/src/effects/blur.css'

type SizeType = 'card' | 'full' | 'cardx2' | 'fullx2'
type ImageProps = {
  className?: string
  src: string
  alt: string
  size?: { width: number | string; height: number | string }
  onLoad?: () => void
  disableLazy?: boolean
  sizeType?: SizeType
  srcSet?: string
  srcSetOnError?: string
}

const Image: FC<ImageProps & { [key: string]: unknown }> = ({
  className,
  src,
  alt,
  size,
  disableLazy,
  sizeType,
  srcSet,
  srcSetOnError,
  ...rest
}): ReactElement => {
  const getDefaultImage = (type: SizeType, srcSet?: string): { src: string; srcSet: string } => {
    const r = random(4, 1)
    const mapping: { [key in SizeType]: string } = {
      card: `${ASSETS}/default/products/Mobile_${r}.jpg`,
      cardx2: `${ASSETS}/default/products/Mobile_${r}x2.jpg`,
      full: `${ASSETS}/default/products/Desktop_${r}.jpg`,
      fullx2: `${ASSETS}/default/products/Desktop_${r}x2.jpg`,
    }

    return {
      src: mapping[type],
      srcSet: `${mapping[type]} 1x, ${mapping[(type + 'x2') as SizeType]} 2x`,
    }
  }

  const handleOnError = (currentTarget: EventTarget & HTMLImageElement, type?: SizeType) => {
    const defaultSet = getDefaultImage(type || 'card')
    currentTarget.onerror = null
    currentTarget.src = defaultSet.src
    currentTarget.srcset = srcSetOnError || defaultSet.srcSet
  }
  return disableLazy ? (
    <img
      className={className}
      src={src}
      alt={alt}
      onError={({ currentTarget }) => handleOnError(currentTarget, sizeType)}
      width={size ? size.width : 'auto'}
      height={size ? size.height : 'auto'}
      {...rest}
    />
  ) : (
    <LazyLoadImage
      alt={alt}
      effect="blur"
      src={src}
      className={className}
      onError={({ currentTarget }) => handleOnError(currentTarget, sizeType)}
      width={size ? size.width : 'auto'}
      height={size ? size.height : 'auto'}
      loading="lazy"
      {...rest}
    />
  )
}

export default Image
