/* eslint-disable no-useless-escape */
export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

export const titlize = (str: string): string =>
  str
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ')

export const camelToSpace = (str: string): string => {
  const temp = str.replace(/([A-Z])/g, ' $1')
  return temp.charAt(0).toUpperCase() + temp.slice(1)
}

export const spaceToSnake = (str: string): string => str.replace(/\s/g, '_').toLowerCase()

export const validateEmail = (email: string): boolean => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)

export const validatePhone = (phone: string): boolean => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)

export const randomColorHex = (): string => '#' + Math.floor(Math.random() * 16777215).toString(16)

export const removeHtmlTags = (str: string | undefined | null): string =>
  str ? str.replace(/(<(script|style)\b[^>]*>).*?(<\/\2>)/gi, '').replace(/(<([^>]+)>)/gi, '') : ''

export const asQueryParam = (str: string): string => str.toLowerCase().replace(',', '')

export const decodeHtmlEntity = (str: string): string => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = str
  return textarea.value
}

export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (new Date().getTime() + Math.random() * 16) % 16 | 0
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })

export const SPECIAL_REGEX_CHARACTERS = ['-', '[', '\\', ']', '{', '}', '(', ')', '*', '+', '?', '.', ',', '^', '$', '|', '#']

/**
 * Escape Regex special characters, except space
 *
 * @param text A text string to be escaped
 * @returns An escaped text string
 */
export const escapeSpecialRegExCharacters = (text: string) =>
  text.replace(new RegExp(`[${SPECIAL_REGEX_CHARACTERS.join('\\')}]`, 'g'), '\\$&')
