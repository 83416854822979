import { ASSETS } from '.'

export const SCHOOLS = [
  { name: 'harvard', icon: `${ASSETS}/schools/harvard-logo.png`, url: 'https://www.harvard.edu/' },
  { name: 'la-trobe', icon: `${ASSETS}/schools/la-trobe-logo.png`, url: 'https://www.latrobe.edu.au/' },
  { name: 'missouri', icon: `${ASSETS}/schools/missouri-logo.png`, url: 'https://www.missouristate.edu/' },
  { name: 'suny', icon: `${ASSETS}/schools/suny-logo.png`, url: 'https://www.suny.edu/' },
  { name: 'ucsf', icon: `${ASSETS}/schools/ucsf-logo.png`, url: 'https://www.ucsf.edu/' },
  { name: 'wku', icon: `${ASSETS}/schools/wku-logo.png`, url: 'https://www.wku.edu/' },
]
