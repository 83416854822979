import { ProductMediaUrl } from '../types'
import { random } from '../utils/random'
import { ASSETS } from './paths'

export const defaultCardImage: ProductMediaUrl = {
  url: `${ASSETS}/default/products/Desktop_${random(4, 1)}`,
  alt: 'On The Hub Logo',
  displayIntent: 'CardImage',
  mediaType: 'Image',
}

export const DEFAULT_PROMO_CATEGORIES = {
  topDeals: [],
  trending: [],
  featured: [],
  peopleAlsoBought: [],
  facultyTrending: [],
}
