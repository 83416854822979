/* eslint-disable no-console */
import { ReactElement, FC, useState, useEffect, useCallback } from 'react'

import './snackbar.css'
import { uuid } from '../../utils'
import { snackPosition } from './helper'
import { SnackbarProps } from './types'

const defaultProps: SnackbarProps = {
  position: 'topRight',
  snackbarType: `plain`,
  duration: 3000,
  children: <></>,
}

const Snackbar: FC<SnackbarProps> = (props): ReactElement => {
  const { children, position, snackbarType, duration } = props
  const [margin, setMargin] = useState<number>(0)
  const [render, setRender] = useState<boolean>(false)
  const animation = `fade-in 0.7s, fade-out 0.5s ease-out ${duration - 0.5}s`
  const icon_class = 'snackbar-'.concat(snackbarType ? snackbarType.concat('-icon') : '-undefined')

  useEffect(() => {
    const timeout = setTimeout(() => {
      const existingPortals = document.querySelectorAll('.portal').length
      setMargin(existingPortals ? 70 * existingPortals : 0)
      setRender(true)
    }, 0)

    return () => clearTimeout(timeout)
  }, [])
  const style = useCallback(
    () =>
      margin
        ? {
            animation,
            marginTop: position?.includes('top') ? `${margin}px` : undefined,
            marginBottom: position?.includes('bottom') ? `${margin}px` : undefined,
          }
        : { animation },
    [animation, margin, position]
  )

  return render ? (
    <div
      key={uuid()}
      role="alert"
      className={`snackbar-card ${snackPosition[position ?? 'topRight']}`}
      style={style()}
      aria-live="assertive"
      aria-atomic="true"
    >
      {snackbarType !== 'plain' && (
        <div className={`${icon_class}`} aria-label={`${snackbarType} icon`}>
          {snackbarType === 'success' && <i className="feather-check" />}
          {snackbarType === 'failure' && <i className="feather-x" />}
          {snackbarType === 'info' && '!'}
        </div>
      )}
      <div className="snackbar-content">{children}</div>
    </div>
  ) : (
    <></>
  )
}

Snackbar.defaultProps = defaultProps

export default Snackbar
