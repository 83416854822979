/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { wrapWithRetryAsync } from '../../services/webstoreApiRequests'
import { ProductWithVariants } from '../../types'
import { eligibilityCleanUp } from '../../utils/eligibilities'
import { getApiProducts } from './productService'

const products: ProductWithVariants[] = []

const initialState = {
  products: products,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get products
export const fetchProducts = createAsyncThunk('products/getAll', async (_, thunkAPI) => {
  try {
    const getApiProductsWithRetry = wrapWithRetryAsync(getApiProducts)
    const apiProducts = (await getApiProductsWithRetry()) as ProductWithVariants[]

    const filteredProducts = eligibilityCleanUp(apiProducts) || []

    return filteredProducts
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.products = action.payload
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload as string
        state.products = []
      })
  },
})

export const { reset } = productSlice.actions
export default productSlice.reducer
